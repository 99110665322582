import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavMenu = ({ className, onClick = () => undefined }) => {
  const location = useLocation();
  const { pathname } = location;

  const renderItem = (text, to, fontNormal) => {
    return (
      <Link to={to} onClick={onClick}>
        <p
          className={`font-anakotmai ${
            fontNormal ? "font-normal" : "font-light"
          } text-xs lg:text-sm text-center text-black px-[10px] lg:px-[20px] mb-[48px] md:mb-0`}
          style={{
            textShadow:
              "0 0 10px #fff, 0 0 10px #fff, 0 0 30px #000, 0 0 0px #000, 0 0 0px #000, 0 0 0px",
          }}
        >
          {text}
        </p>
      </Link>
    );
  };
  return (
    <div className={`flex flex-row absolutex z-10 ${className}`}>
      {renderItem(
        "แนะนำโครงการ",
        "/project-introduction",
        pathname === "/" || pathname === "/project-introduction",
      )}
      {renderItem("J FORREST II", "/j-forest-ii", pathname === "/j-forest-ii")}
      {renderItem("OCEAN AMATA", "/ocean-amata", pathname === "/ocean-amata")}
      {renderItem("โปรโมชั่น", "/promotion", pathname === "/promotion")}
      {/* <Link to="/project-introduction" onClick={onClick}>
        <p
          className={`font-anakotmai ${
            pathname === "/" || pathname === "/project-introduction"
              ? "font-normal"
              : "font-light"
          } text-xs lg:text-sm text-center text-black px-[10px] lg:px-[20px] mb-[48px] md:mb-0`}
        >
          แนะนำโครงการ
        </p>
      </Link>
      <Link to="/j-forest-ii" onClick={onClick}>
        <p
          className={`font-anakotmai ${
            pathname === "/j-forest-ii" ? "font-normal" : "font-light"
          } text-xs lg:text-sm text-center text-black px-[10px] lg:px-[20px] mb-[48px] md:mb-0`}
        >
          J FORREST II
        </p>
      </Link>
      <Link to="/ocean-amata" onClick={onClick}>
        <p
          className={`font-anakotmai ${
            pathname === "/ocean-amata" ? "font-normal" : "font-light"
          } text-xs lg:text-sm text-center text-black px-[10px] lg:px-[20px] mb-[48px] md:mb-0`}
        >
          OCEAN AMATA
        </p>
      </Link>
      <Link to="/promotion" onClick={onClick}>
        <p
          className={`font-anakotmai ${
            pathname === "/promotion" ? "font-normal" : "font-light"
          } text-xs lg:text-sm text-center text-black px-[10px] lg:px-[20px] mb-[48px] md:mb-0`}
        >
          โปรโมชั่น
        </p>
      </Link> */}
    </div>
  );
};

export default NavMenu;
