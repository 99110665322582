import React from "react";

import CarouselImage from "../components/CarouselImage";

const Pages = (props) => {
  const { project, data } = props;
  console.log("render ::", { project, data });

  return (
    <div className="flex items-center">
      <CarouselImage project={project} items={data} />
    </div>
  );
};

export default Pages;
