import React, { useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { useMediaQuery } from 'react-responsive';

export default function VideoPlayer({
  isActive = false,
  index,
  src = '',
  onEnded = () => undefined,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef();

  const isDesktop = useMediaQuery({ query: '(min-width: 456px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 455px)' });

  useEffect(() => {
    setIsPlaying(isActive);
  }, [isActive, src, index]);

  const handleEnded = () => {
    setIsPlaying(false);
    onEnded();
  };

  const renderDesktop = () => {
    return (
      <div className="player-wrapper">
        {isPlaying && (
          <ReactPlayer
            ref={videoRef}
            url={src}
            // controls
            playing={isPlaying}
            onEnded={handleEnded}
            muted
            loop={false}
            width="auto"
            height="100vh"
            // width="100vw"
            // height="auto"
            // // height="100vh"
          />
        )}
      </div>
    );
  };
  const renderMobile = () => {
    return (
      <div className="flex justify-center items-center bg-black h-full">
        {isPlaying && (
          <ReactPlayer
            ref={videoRef}
            url={src}
            key={src}
            // controls
            playing={isPlaying}
            onEnded={handleEnded}
            muted
            loop={false}
            pip={true}
            width="100vw"
            height="100vh"
            // width="auto"
            // height="100vh"
          />
        )}
      </div>
    );
  };

  return (
    <>
      {isDesktop && renderDesktop()}
      {isMobile && renderMobile()}
    </>
  );
}
