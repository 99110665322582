import React from "react";
import { motion } from "framer-motion";

const Social = (props) => {
  const { className } = props;

  return (
    <div className={`flex flex-row absolutex z-10 ${className}`}>
      <a
        // href={`https://www.messenger.com/t/${process.env.REACT_APP_FACE_BOOK_PAGE_ID}`} // facebook messenger
        href={process.env.REACT_APP_FACE_BOOK_PAGE}
        target="_blank"
        rel="noreferrer"
      >
        <motion.svg
          className="inline mx-[12px] md:mx-[5px] hover:fill-gray"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // variants={variants.prevArrow}
        >
          <path
            d="M10.9641 8.01801V7.26613C10.9641 5.56559 11.5016 5.61445 13.1157 5.61445H13.5834V3.34231C13.5834 3.12235 13.4035 2.94238 13.1835 2.94238H11.3625C9.61434 2.94238 8.18402 4.3727 8.18402 6.1209V6.86332H8.1782V8.01801H6.41113V10.6256H8.1782V17.0576H10.9614V10.6256H13.1982L13.5888 8.01801H10.9641Z"
            stroke="#171A20"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </motion.svg>
      </a>
      <a
        href={process.env.REACT_APP_INSTAGRAM}
        target="_blank"
        rel="noreferrer"
      >
        <motion.svg
          className="inline mx-[12px] md:mx-[5px] hover:fill-gray"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // variants={variants.prevArrow}
        >
          <path
            d="M12.7265 16.8863H7.27369C4.98576 16.8863 3.11377 15.0144 3.11377 12.7264V7.27357C3.11377 4.98564 4.98576 3.11369 7.27369 3.11369H12.7265C15.0144 3.11369 16.8864 4.98564 16.8864 7.27357V12.7264C16.8864 15.0144 15.0144 16.8863 12.7265 16.8863Z"
            stroke="#171A20"
            strokeWidth="1.2"
            strokeMiterlimit="10"
          />
          <path
            d="M13.1975 9.86555C13.1975 11.6313 11.766 13.0627 10.0003 13.0627C8.23463 13.0627 6.80322 11.6313 6.80322 9.86555C6.80322 8.09981 8.23463 6.66844 10.0003 6.66844C11.766 6.66844 13.1975 8.09981 13.1975 9.86555Z"
            stroke="#171A20"
            strokeWidth="1.2"
            strokeMiterlimit="10"
          />
          <path
            d="M14.0439 6.14206V6.15288"
            stroke="#171A20"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </motion.svg>
      </a>

      <a href={process.env.REACT_APP_LINE} target="_blank" rel="noreferrer">
        <motion.svg
          className="inline mx-[12px] md:mx-[5px] hover:fill-gray"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // variants={variants.prevArrow}
        >
          <path
            d="M5.5509 10.7627C5.40527 10.7627 5.26465 10.6924 5.26465 10.5569V7.29316C5.26465 7.15254 5.42534 7.09223 5.59105 7.09223C5.75171 7.09223 5.91743 7.15254 5.91743 7.29316V10.1904H7.14259C7.27312 10.1904 7.33837 10.3309 7.33837 10.4765C7.33837 10.6171 7.27312 10.7627 7.14259 10.7627H5.5509Z"
            fill="#171A20"
          />
          <path
            d="M5.5509 10.7627C5.40527 10.7627 5.26465 10.6924 5.26465 10.5569V7.29316C5.26465 7.15254 5.42534 7.09223 5.59105 7.09223C5.75171 7.09223 5.91743 7.15254 5.91743 7.29316V10.1904H7.14259C7.27312 10.1904 7.33837 10.3309 7.33837 10.4765C7.33837 10.6171 7.27312 10.7627 7.14259 10.7627H5.5509Z"
            stroke="#171A20"
            strokeWidth="0.2"
            strokeMiterlimit="10"
          />
          <path
            d="M7.85059 7.29315C7.85059 7.15252 8.01118 7.09221 8.17693 7.09221C8.33759 7.09221 8.5033 7.15252 8.5033 7.29315V10.5569C8.5033 10.6924 8.33759 10.7627 8.17693 10.7627C8.01118 10.7627 7.85059 10.6924 7.85059 10.5569V7.29315Z"
            fill="#171A20"
          />
          <path
            d="M7.85059 7.29315C7.85059 7.15252 8.01118 7.09221 8.17693 7.09221C8.33759 7.09221 8.5033 7.15252 8.5033 7.29315V10.5569C8.5033 10.6924 8.33759 10.7627 8.17693 10.7627C8.01118 10.7627 7.85059 10.6924 7.85059 10.5569V7.29315Z"
            stroke="#171A20"
            strokeWidth="0.2"
            strokeMiterlimit="10"
          />
          <path
            d="M10.9945 10.5569L9.91493 8.53835V10.5569C9.91493 10.6924 9.74927 10.7627 9.58858 10.7627C9.42286 10.7627 9.26221 10.6924 9.26221 10.5569V7.29816C9.26221 7.15754 9.42286 7.09223 9.58858 7.09223C9.82455 7.09223 9.91993 7.14241 10.0756 7.43869L11.0447 9.31157V7.29316C11.0447 7.15254 11.2053 7.09223 11.3711 7.09223C11.5317 7.09223 11.6974 7.15254 11.6974 7.29316V10.5569C11.6974 10.6924 11.5317 10.7627 11.3711 10.7627C11.2155 10.7627 11.0798 10.7125 10.9945 10.5569Z"
            fill="#171A20"
          />
          <path
            d="M10.9945 10.5569L9.91493 8.53835V10.5569C9.91493 10.6924 9.74927 10.7627 9.58858 10.7627C9.42286 10.7627 9.26221 10.6924 9.26221 10.5569V7.29816C9.26221 7.15754 9.42286 7.09223 9.58858 7.09223C9.82455 7.09223 9.91993 7.14241 10.0756 7.43869L11.0447 9.31157V7.29316C11.0447 7.15254 11.2053 7.09223 11.3711 7.09223C11.5317 7.09223 11.6974 7.15254 11.6974 7.29316V10.5569C11.6974 10.6924 11.5317 10.7627 11.3711 10.7627C11.2155 10.7627 11.0798 10.7125 10.9945 10.5569Z"
            stroke="#171A20"
            strokeWidth="0.2"
            strokeMiterlimit="10"
          />
          <path
            d="M13.1088 8.66888H13.8721C14.0026 8.66888 14.0779 8.79441 14.0779 8.93001C14.0779 9.04544 14.0126 9.18107 13.8721 9.18107H13.1088V10.1904H14.5298C14.6603 10.1904 14.7357 10.3259 14.7357 10.4815C14.7357 10.6171 14.6704 10.7627 14.5298 10.7627H12.7423C12.5967 10.7627 12.4561 10.6924 12.4561 10.5569V7.29816C12.4561 7.16254 12.5967 7.09223 12.7423 7.09223H14.5298C14.6704 7.09223 14.7357 7.23785 14.7357 7.37348C14.7357 7.52907 14.6603 7.66469 14.5298 7.66469H13.1088V8.66888Z"
            fill="#171A20"
          />
          <path
            d="M13.1088 8.66888H13.8721C14.0026 8.66888 14.0779 8.79441 14.0779 8.93001C14.0779 9.04544 14.0126 9.18107 13.8721 9.18107H13.1088V10.1904H14.5298C14.6603 10.1904 14.7357 10.3259 14.7357 10.4815C14.7357 10.6171 14.6704 10.7627 14.5298 10.7627H12.7423C12.5967 10.7627 12.4561 10.6924 12.4561 10.5569V7.29816C12.4561 7.16254 12.5967 7.09223 12.7423 7.09223H14.5298C14.6704 7.09223 14.7357 7.23785 14.7357 7.37348C14.7357 7.52907 14.6603 7.66469 14.5298 7.66469H13.1088V8.66888Z"
            stroke="#171A20"
            strokeWidth="0.2"
            strokeMiterlimit="10"
          />
          <path
            d="M10 2.8149C5.84069 2.8149 2.46875 5.55152 2.46875 8.92746C2.46875 12.1159 5.47706 14.7329 9.31525 15.0138V17.1851C10.5542 17.1851 17.5312 13.737 17.5312 8.92746C17.5312 5.55152 14.1594 2.8149 10 2.8149Z"
            stroke="#171A20"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </motion.svg>
      </a>

      <a
        aria-label={`tel:${process.env.REACT_APP_TEL}`}
        href={`tel:${process.env.REACT_APP_TEL}`}
        target="_blank"
        rel="noreferrer"
      >
        <motion.svg
          className="inline mx-[12px] md:mx-[5px] hover:fill-gray"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          // variants={variants.prevArrow}
        >
          <path
            d="M7.96518 4.83972C7.5061 3.69202 6.31568 2.84292 5.25766 3.4821C4.99957 3.63802 4.75909 3.82562 4.5423 4.04236C3.83625 4.74856 3.43945 5.70637 3.43945 6.70507C3.43945 12.4243 8.07565 17.0605 13.7949 17.0605C14.7935 17.0605 15.7514 16.6638 16.4577 15.9576C16.6744 15.7409 16.862 15.5004 17.0179 15.2423C17.6571 14.1843 16.808 12.9939 15.6603 12.5348L14.2569 11.9735C13.4818 11.6634 12.6022 12.0404 12.2922 12.8155L11.9123 13.7652C11.9122 13.7654 11.9119 13.7656 11.9116 13.7656V13.7656C9.05235 13.7656 6.73438 11.4477 6.73438 8.58836V8.58836C6.73438 8.58808 6.73456 8.58782 6.73482 8.58772L7.68452 8.20784C8.45958 7.89782 8.83657 7.01818 8.52654 6.24312L7.96518 4.83972Z"
            stroke="#171A20"
            strokeWidth="1.2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </motion.svg>
      </a>
    </div>
  );
};

export default Social;
