import React, { useState } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

import NavMenu from "./NavMenu";
import Social from "./Social";
import Logo from "./Logo";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const isDesktop = useMediaQuery({ query: "(min-width: 456px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 455px)" });

  const handleMenu = (active) => {
    // console.log("handleMenu ::", active);
    setIsOpen(active);
  };

  const renderMobile = () => (
    <>
      <div className="container mx-auto flex flex-row justify-between items-center w-fulls py-[16px] absolute z-10">
        <Logo />
        <div
          className="py-[4px] px-[20px] bg-gray-20 rounded"
          onClick={() => handleMenu(true)}
        >
          <p className="font-anakotmai font-normal text-sm text-center text-white">
            เมนู
          </p>
        </div>
      </div>

      <motion.div
        className="container mx-auto flex justify-center items-center w-full h-full bg-gray-80 absolute z-20"
        animate={isOpen ? "visible" : "hidden"}
        initial="hidden"
        variants={{
          hidden: {
            opacity: 0,
            y: -15,
            scaleX: 1.05,
            scaleY: 1.05,
            pointerEvents: "none",
          },
          visible: {
            opacity: 1,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            pointerEvents: "auto",
          },
        }}
        transition={{
          ease: "easeOut",
          duration: 0.3,
        }}
      >
        <div className="flex-1 flex flex-column relative bg-white mt-[88px] mb-[31px]">
          <div className="flex-1 flex flex-row justify-between px-[16px] py-[16px]">
            <Logo className="" />
            <div onClick={() => handleMenu(false)}>
              <p className="font-anakotmai font-normal text-black">X</p>
            </div>
          </div>
          <div className="my-[80px]">
            <NavMenu
              className="flex-column"
              onClick={() => handleMenu(false)}
            />
          </div>
          <div className="pb-[100px]">
            <Social className="justify-center" />
          </div>
        </div>
      </motion.div>
    </>
  );

  const renderDesktop = () => (
    <div className="container mx-auto flex flex-row justify-between items-center w-fulls px-[20px] lg:px-[40px] py-[40px] absolute z-10">
      <Logo />
      {/* <BrowserRouter basename="/">
        <NavMenu />
      </BrowserRouter> */}
      <NavMenu />
      <Social />
    </div>
  );

  return (
    <>
      {isDesktop && renderDesktop()}
      {isMobile && renderMobile()}
    </>
  );
};

export default Header;
