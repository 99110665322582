import React from 'react';
// import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';

import _ from 'lodash';

import PageTemplate from './pages';

import Header from './components/Header';

const RouteContainer = posed.div({
  enter: { opacity: 1, delay: 300, beforeChildren: true },
  exit: { opacity: 0 },
});

const AppRouter = (props) => {
  const { projectJson } = props;

  const PageJforrest = () => {
    const getItems = _.get(projectJson, ['items', 'j-forest-ii']);
    return <PageTemplate project={'j-forest-ii'} data={getItems} />;
  };

  const PageOceanAmata = () => {
    const getItems = _.get(projectJson, ['items', 'ocean-amata']);
    return <PageTemplate project={'ocean-amata'} data={getItems} />;
  };

  const PageProjectIntroduction = () => {
    const getItems = _.get(projectJson, ['items', 'project-introduction']);
    return <PageTemplate project={'project-introduction'} data={getItems} />;
  };

  const PagePromotion = () => {
    const getItems = _.get(projectJson, ['items', 'promotion']);
    return <PageTemplate project={'promotion'} data={getItems} />;
  };

  return (
    <Router>
      <Route
        render={({ location }) => {
          return (
            <>
              <Header />
              <div id="content-container w-screen h-screen">
                <PoseGroup>
                  <RouteContainer key={location.pathname}>
                    <Switch location={location}>
                      <Route
                        exact
                        path="/"
                        component={PageProjectIntroduction}
                      />
                      <Route path="/j-forest-ii" component={PageJforrest} />
                      <Route path="/ocean-amata" component={PageOceanAmata} />
                      <Route
                        path="/project-introduction"
                        component={PageProjectIntroduction}
                      />
                      <Route path="/promotion" component={PagePromotion} />
                    </Switch>
                  </RouteContainer>
                </PoseGroup>
              </div>
            </>
          );
        }}
      />
    </Router>
  );
};

export default AppRouter;
