import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';
import VideoPlayer from './VideoPlayer';

import { mediaQueryMobile } from '../utils';
import { useMediaQuery } from 'react-responsive';

import 'bootstrap/dist/css/bootstrap.min.css';

import './style.css';

const CarouselImage = (props) => {
  const { project, items = [] } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [renderType, setRenderType] = useState('video');

  // const isDesktop = useMediaQuery({ query: "(min-width: 456px)" });
  const isMobile = useMediaQuery({ query: '(max-width: 455px)' });

  const onExiting = () => {
    // this.animating = true;
  };

  const onExited = () => {
    // this.animating = false;
  };

  const next = () => {
    // if (this.animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    // if (this.animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const handleEnded = () => {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    console.log('handleEnded ::', nextIndex);

    setActiveIndex((activeIndex) => {
      console.log('handleEnded activeIndex ::', activeIndex);
      return activeIndex === 0 ? 1 : nextIndex;
    });
  };

  const isImage = (str) => {
    let regex = new RegExp(/[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/);
    return regex.test(str);
    // console.log("isImage ::", str, regex.test(str));
    // if (regex.test(str) === true) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  useEffect(() => {
    const currentIndexType = _.chain(items)
      .find((i, k) => _.toNumber(k) === activeIndex)
      .get('src')
      // .get("type")
      .value();
    // console.log("USE EFFECT ::", currentIndexType);
    setRenderType(isImage(currentIndexType) ? 'image' : 'video');
  }, [items, activeIndex]);

  const renderVideoPlayer = (item, key) => {
    const isVertical = project === 'promotion';
    console.log(
      'renderVideoPlayer ::',
      project,
      isVertical,
      isMobile
      // `${process.env.PUBLIC_URL}/assets/video/${project}/vertical/${item?.src}`
    );
    if (isVertical && isMobile) {
      console.log(
        'renderVideoPlayer URL ::',
        `${process.env.PUBLIC_URL}/assets/video/${project}/vertical/${item?.src}`
      );
      return (
        <div className="h-fulls">
          <VideoPlayer
            key={item?.src}
            index={key}
            isActive={activeIndex === key && renderType === 'video'}
            src={`${process.env.PUBLIC_URL}/assets/video/${project}/vertical/${item?.src}`}
            onEnded={handleEnded}
          />
        </div>
      );
    }
    console.log(
      'renderVideoPlayer URL ::',
      `${process.env.PUBLIC_URL}/assets/video/${project}/horizontal/${item?.src}`
    );
    return (
      <div className="h-full w-full bg-black">
        <VideoPlayer
          key={item?.src}
          index={key}
          isActive={activeIndex === key && renderType === 'video'}
          src={`${process.env.PUBLIC_URL}/assets/video/${project}/horizontal/${item?.src}`}
          onEnded={handleEnded}
        />
      </div>
    );
  };

  const renderImage = (item) => {
    // console.log(
    //   'renderImage ::',
    //   `${process.env.PUBLIC_URL}/assets/images/${project}/vertical/${item?.src}`
    // );
    return (
      <>
        <div className="h-full">
          <picture>
            <source
              media={mediaQueryMobile}
              srcSet={`${process.env.PUBLIC_URL}/assets/images/${project}/vertical/${item.src}`}
            />
            <img
              // className="img-fluid"
              className="w-screen h-screen object-cover"
              src={`${process.env.PUBLIC_URL}/assets/images/${project}/horizontal/${item.src}`}
              alt={item.altText}
            />
          </picture>
        </div>
        <div
          className={`${
            item.isCaptionBottom ? 'bottom-20' : 'top-24 md:top-32'
          } carousel-caption`}
        >
          <div className="flex flex-column items-center">
            {item?.logo && (
              <img
                className="w-[195px] md:w-[265px] img-fluid pb-4"
                src={`${process.env.PUBLIC_URL}/assets/images/${project}/${item.logo}`}
                alt=""
              />
            )}
            <p
              className={`text-xl md:text-2xl font-medium pb-2.5 ${
                item?.textColor ? `text-[${item?.textColor}]` : 'text-black'
              }`}
            >
              {_.map(_.split(item?.header, '\n'), (i, k) => {
                return (
                  <span key={`header-${k}`}>
                    {i}
                    <br />
                  </span>
                );
              })}
            </p>
            <p
              className={`text-sm md:text-base font-light ${
                item?.textColor ? `text-[${item?.textColor}]` : 'text-black'
              }`}
            >
              {item?.caption}
            </p>
          </div>
        </div>
        {item?.isGoogleMaps && (
          <div className="absolute bottom-24 md:bottom-32">
            <a
              href={process.env.REACT_APP_GOOGLE_MAPS}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex flex-column items-center border-2 border-solid border-white rounded px-[80px] md:px-[50px] py-[10px]">
                <p className="text-sm text-white">ดูแผนที่บน Google Maps</p>
              </div>
            </a>
          </div>
        )}
        {item?.subCaption && (
          <div className="flex flex-row absolute bottom-20 md:bottom-32">
            {_.map(item?.subCaption, (item, key) => (
              <div
                className="items-center mx-[10px] my-[10px] md:mx-[50px] md:my-[10px]"
                key={key}
              >
                <p className="text-xs md:text-sm text-white">{item.sub1}</p>
                <p className="text-sm md:text-xl text-white">{item.sub2}</p>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  const slides = _.map(items, (item, key) => {
    // return null;
    console.log('slides +++', renderType, item.type);
    return (
      <CarouselItem
        className="flex justify-center"
        onExiting={onExiting}
        onExited={onExited}
        key={item.src}
      >
        {renderType === 'video' && item.type === 'video'
          ? renderVideoPlayer(item, key)
          : renderImage(item, key)}
      </CarouselItem>
    );
  });

  const renderControllerMedia = () => {
    return (
      <div className="flex justify-center absolute bottom-16 md:bottom-[4.5rem] w-full">
        <div className="flex flex-row justify-center">
          <CarouselControl
            className="top-[20px] md:top-[23px] left-[20%] md:left-[32%] lg:left-[35%] drop-shadow-black"
            direction="prev"
            directionText="Previous"
            onClickHandler={previous}
          />
          <div className="font-anakotmai text-sm md:text-base text-white absolute z-10 drop-shadow-black">
            <span className="text-xl md:text-3xl">
              {_.padStart(activeIndex + 1, 2, 0)}
            </span>{' '}
            / {_.padStart(_.size(items), 2, 0)}
          </div>
          <CarouselControl
            className="top-[20px] md:top-[23px] right-[20%] md:right-[32%] lg:right-[35%] drop-shadow-black"
            direction="next"
            directionText="Next"
            onClickHandler={next}
          />
        </div>
      </div>
    );
  };

  const renderCarousel = ({ interval = 5000, key = '' }) => {
    return (
      <Carousel
        key={key}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        keyboard={false}
        pause={false}
        ride="carousel"
        interval={renderType === 'video' ? false : interval}
        // interval={interval}
        slide={false}
        className="carousel-fade"
      >
        {slides}
        {renderControllerMedia()}
      </Carousel>
    );
  };

  return (
    <>
      {renderType === 'video'
        ? renderCarousel({ interval: false, key: renderType })
        : renderCarousel({ interval: 5000, key: renderType })}
      {/* {renderCarousel({ interval: 5000, key: renderType })} */}
    </>
  );
};

export default CarouselImage;
