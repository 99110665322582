import React, { useState, useEffect } from "react";

import AppRouter from "./router";
import "./App.css";

const App = () => {
  const [projectJson, setProjectJson] = useState([]);

  const getProjectJson = () => {
    return require("./assets/data.json");
  };

  const initData = async () => {
    const localProjectJson = await getProjectJson();
    setProjectJson(localProjectJson);
    // console.log("initData ::", { localProjectJson });
  };

  useEffect(() => {
    initData();
  });

  return (
    <div className="App">
      <header className="App-header relative overflow-hidden">
        <AppRouter projectJson={projectJson} />
      </header>
    </div>
  );
};

export default App;
