import React from "react";

const Logo = (props) => {
  const { className } = props;
  return (
    <div className={className}>
      <svg
        width="93"
        height="26"
        viewBox="0 0 93 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.1183 11.1029H39.0588V3.79333L39.0114 0.179993H44.5101V10.8932C44.5101 11.9567 44.0518 12.8749 43.1185 13.6442C42.1834 14.4135 41.1774 14.7991 40.0969 14.7991H27.4494C26.3943 14.7991 25.5032 14.4135 24.7643 13.6442C24.0288 12.8749 23.6653 12.1444 23.6653 11.4377V7.37455H29.1183V11.1046V11.1029Z"
          fill="#171A20"
        />
        <path
          d="M52.7867 14.7991H47.1951V0.179993H52.7867V14.7991ZM55.7169 11.8756L59.7953 14.821H68.3138L60.1115 9.31564L68.0466 0.205355H60.9264L55.7153 6.37018V11.8773L55.7169 11.8756Z"
          fill="#171A20"
        />
        <path
          d="M86.1877 8.94872H74.9232C73.694 8.77119 72.693 8.32649 71.9355 7.62141C71.178 6.92478 70.8027 6.04723 70.8027 5.0006V4.01822C70.8027 2.9682 71.1915 2.07205 71.9744 1.3247C72.7488 0.57396 73.7126 0.205355 74.8505 0.205355L92.1462 0.179993V3.69864L76.6767 3.7257V5.75471H87.9276C89.1602 5.87646 90.1679 6.22477 90.9626 6.80473C91.7489 7.383 92.1462 8.20137 92.1462 9.248V10.9591C92.1462 12.0278 91.732 12.934 90.9237 13.6746C90.1138 14.422 89.1433 14.7991 88.0189 14.7991H70.7722V11.3311L86.186 11.3954V8.94872H86.1877Z"
          fill="#171A20"
        />
        <path
          d="M26.1254 21.3917L33.3166 21.4238V22.9439L26.1254 22.9101V25.7727H23.4336L23.4573 23.8941V20.5074C23.4573 19.9849 23.6382 19.5436 24 19.1784C24.3653 18.8114 24.7998 18.6305 25.3087 18.6305H33.8374L33.8036 20.4059H26.1271V21.3917H26.1254Z"
          fill="#171A20"
        />
        <path
          d="M42.9357 18.6322H45.6276V23.8941C45.6276 24.4166 45.4433 24.8596 45.0814 25.2231C44.7162 25.5883 44.2833 25.7726 43.7744 25.7726H35.2474L35.2356 23.9956L35.2136 23.4596L35.2356 22.8576L35.2474 18.6322H37.9055V23.9973H42.9594L42.9357 18.6322Z"
          fill="#171A20"
        />
        <path
          d="M57.3318 20.4059H53.6592V25.7727H50.6918V20.4059H47.0413V18.6305H57.3335V20.4059H57.3318Z"
          fill="#171A20"
        />
        <path
          d="M66.3964 18.6322H69.0916V23.8941C69.0916 24.4166 68.9073 24.8596 68.5404 25.2231C68.1803 25.5883 67.7474 25.7726 67.2368 25.7726H58.7098L58.6997 23.9956L58.676 23.4596L58.6997 22.8576L58.7098 18.6322H61.3695V23.9973H66.4235L66.3964 18.6322Z"
          fill="#171A20"
        />
        <path
          d="M73.1241 25.7743H70.3799V18.6322H78.5281C79.0522 18.6322 79.5003 18.8131 79.8757 19.18C80.2477 19.5453 80.4387 19.9866 80.4387 20.509V21.3494C80.4387 21.7755 80.3052 22.1542 80.0414 22.489C79.7742 22.8255 79.3262 23.0402 78.6955 23.1366L80.6924 25.7845H77.7706L75.0331 21.5489H77.681V20.3552H73.1241V25.7743Z"
          fill="#171A20"
        />
        <path
          d="M84.8081 21.3917L92.0009 21.4238V22.9439L84.8081 22.91V23.9956H89.8485L92.4879 23.9854L92.52 25.7743H83.9914C83.4824 25.7743 83.0496 25.59 82.6861 25.2248C82.3208 24.8613 82.1416 24.4183 82.1416 23.8958V20.509C82.1416 19.9866 82.3208 19.5453 82.6861 19.18C83.0496 18.8131 83.4841 18.6322 83.9914 18.6322H92.52L92.4879 20.4076H84.8098V21.3934L84.8081 21.3917Z"
          fill="#171A20"
        />
        <path
          d="M1.71621 25.82H0V21.5489C0 21.5489 1.41186 20.0035 1.71621 19.4117V25.8217V25.82Z"
          fill="#171A20"
        />
        <path
          d="M4.55688 25.82H6.29508V8.41949C6.29508 8.41949 5.39385 11.8959 4.55688 13.8133V25.82Z"
          fill="#171A20"
        />
        <path
          d="M2.30811 18.4716C2.30811 18.4716 3.64726 16.194 3.96514 15.1947V25.8183H2.30811V18.4699V18.4716Z"
          fill="#171A20"
        />
        <path
          d="M13.9089 25.82H15.6251V21.5489C15.6251 21.5489 14.215 20.0035 13.9089 19.4117V25.8217V25.82Z"
          fill="#171A20"
        />
        <path
          d="M11.0683 25.82H9.33179V8.41949C9.33179 8.41949 10.233 11.8959 11.0683 13.8133V25.82Z"
          fill="#171A20"
        />
        <path
          d="M13.3172 18.4716C13.3172 18.4716 11.978 16.194 11.6602 15.1947V25.8183H13.3172V18.4699V18.4716Z"
          fill="#171A20"
        />
        <path
          d="M6.85815 25.82H8.73838V5.46896L7.79827 1.05923L6.85815 5.45375V25.82Z"
          fill="#171A20"
        />
        <path
          d="M29.1183 11.1029H39.0588V3.79333L39.0114 0.179993H44.5101V10.8932C44.5101 11.9567 44.0518 12.8749 43.1185 13.6442C42.1834 14.4135 41.1774 14.7991 40.0969 14.7991H27.4494C26.3943 14.7991 25.5032 14.4135 24.7643 13.6442C24.0288 12.8749 23.6653 12.1444 23.6653 11.4377V7.37455H29.1183V11.1046V11.1029Z"
          fill="#171A20"
        />
        <path
          d="M52.7867 14.7991H47.1951V0.179993H52.7867V14.7991ZM55.7169 11.8756L59.7953 14.821H68.3138L60.1115 9.31564L68.0466 0.205355H60.9264L55.7153 6.37018V11.8773L55.7169 11.8756Z"
          fill="#171A20"
        />
        <path
          d="M86.1877 8.94872H74.9232C73.694 8.77119 72.693 8.32649 71.9355 7.62141C71.178 6.92478 70.8027 6.04723 70.8027 5.0006V4.01822C70.8027 2.9682 71.1915 2.07205 71.9744 1.3247C72.7488 0.57396 73.7126 0.205355 74.8505 0.205355L92.1462 0.179993V3.69864L76.6767 3.7257V5.75471H87.9276C89.1602 5.87646 90.1679 6.22477 90.9626 6.80473C91.7489 7.383 92.1462 8.20137 92.1462 9.248V10.9591C92.1462 12.0278 91.732 12.934 90.9237 13.6746C90.1138 14.422 89.1433 14.7991 88.0189 14.7991H70.7722V11.3311L86.186 11.3954V8.94872H86.1877Z"
          fill="#171A20"
        />
        <path
          d="M26.1254 21.3917L33.3166 21.4238V22.9439L26.1254 22.9101V25.7727H23.4336L23.4573 23.8941V20.5074C23.4573 19.9849 23.6382 19.5436 24 19.1784C24.3653 18.8114 24.7998 18.6305 25.3087 18.6305H33.8374L33.8036 20.4059H26.1271V21.3917H26.1254Z"
          fill="#171A20"
        />
        <path
          d="M42.9357 18.6322H45.6276V23.8941C45.6276 24.4166 45.4433 24.8596 45.0814 25.2231C44.7162 25.5883 44.2833 25.7726 43.7744 25.7726H35.2474L35.2356 23.9956L35.2136 23.4596L35.2356 22.8576L35.2474 18.6322H37.9055V23.9973H42.9594L42.9357 18.6322Z"
          fill="#171A20"
        />
        <path
          d="M57.3318 20.4059H53.6592V25.7727H50.6918V20.4059H47.0413V18.6305H57.3335V20.4059H57.3318Z"
          fill="#171A20"
        />
        <path
          d="M66.3964 18.6322H69.0916V23.8941C69.0916 24.4166 68.9073 24.8596 68.5404 25.2231C68.1803 25.5883 67.7474 25.7726 67.2368 25.7726H58.7098L58.6997 23.9956L58.676 23.4596L58.6997 22.8576L58.7098 18.6322H61.3695V23.9973H66.4235L66.3964 18.6322Z"
          fill="#171A20"
        />
        <path
          d="M73.1241 25.7743H70.3799V18.6322H78.5281C79.0522 18.6322 79.5003 18.8131 79.8757 19.18C80.2477 19.5453 80.4387 19.9866 80.4387 20.509V21.3494C80.4387 21.7755 80.3052 22.1542 80.0414 22.489C79.7742 22.8255 79.3262 23.0402 78.6955 23.1366L80.6924 25.7845H77.7706L75.0331 21.5489H77.681V20.3552H73.1241V25.7743Z"
          fill="#171A20"
        />
        <path
          d="M84.8081 21.3917L92.0009 21.4238V22.9439L84.8081 22.91V23.9956H89.8485L92.4879 23.9854L92.52 25.7743H83.9914C83.4824 25.7743 83.0496 25.59 82.6861 25.2248C82.3208 24.8613 82.1416 24.4183 82.1416 23.8958V20.509C82.1416 19.9866 82.3208 19.5453 82.6861 19.18C83.0496 18.8131 83.4841 18.6322 83.9914 18.6322H92.52L92.4879 20.4076H84.8098V21.3934L84.8081 21.3917Z"
          fill="#171A20"
        />
        <path
          d="M1.71621 25.82H0V21.5489C0 21.5489 1.41186 20.0035 1.71621 19.4117V25.8217V25.82Z"
          fill="#171A20"
        />
        <path
          d="M4.55688 25.82H6.29508V8.41949C6.29508 8.41949 5.39385 11.8959 4.55688 13.8133V25.82Z"
          fill="#171A20"
        />
        <path
          d="M2.30811 18.4716C2.30811 18.4716 3.64726 16.194 3.96514 15.1947V25.8183H2.30811V18.4699V18.4716Z"
          fill="#171A20"
        />
        <path
          d="M13.9089 25.82H15.6251V21.5489C15.6251 21.5489 14.215 20.0035 13.9089 19.4117V25.8217V25.82Z"
          fill="#171A20"
        />
        <path
          d="M11.0683 25.82H9.33179V8.41949C9.33179 8.41949 10.233 11.8959 11.0683 13.8133V25.82Z"
          fill="#171A20"
        />
        <path
          d="M13.3172 18.4716C13.3172 18.4716 11.978 16.194 11.6602 15.1947V25.8183H13.3172V18.4699V18.4716Z"
          fill="#171A20"
        />
        <path
          d="M6.85815 25.82H8.73838V5.46896L7.79827 1.05923L6.85815 5.45375V25.82Z"
          fill="#171A20"
        />
      </svg>
    </div>
  );
};

export default Logo;
